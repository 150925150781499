
@mixin largeWin {
    height: unset;
    width: 95%;
    margin: 1vh;
    margin-left: auto;
    margin-right: auto;

    .playerSection {
        /* 1 */
        margin-top: 0;
        width: 100%;
        height: 20vh;


        .winnerName {
            margin: auto;
            text-align: center;
            font-family: "Gagalin";
            color: white;

            &.large {
                font-size: 11.5vh;
            }

            &.medium {
                font-size: 8vh;
            }

            &.small {
                font-size: 6vh;
            }
        }

        .number {
            font-size: 10vh;
        }

        .potato {
            height: 22vh;
            top: 2vh;

            &.left {
                left: -8vh;
            }

            &.right {
                right: -8vh;
            }
        }
    }
}

@mixin mediumWin {
    height: unset;
    width: 45%;
    margin: 1vh;
    margin-left: auto;
    margin-right: auto;

    .playerSection {
        /* 2 */
        margin-top: 0;
        width: 100%;
        height: 17.5vh;

        .winnerName {
            margin: auto;
            text-align: center;
            font-size: 8vh;
            font-family: "Gagalin";
            color: white;

            &.large {
                font-size: 8vh;
            }

            &.medium {
                font-size: 6vh;
            }

            &.small {
                font-size: 4vh;
            }
        }

        .number {
            font-size: 8vh;
        }

        .potato {
            height: 17.5vh;
            top: 3vh;

            &.left {
                left: -7vh;
            }

            &.right {
                right: -7vh;
            }
        }
    }
}

@mixin smallWin {
    height: unset;
    width: 28%;
    margin: 1vh;
    margin-left: auto;
    margin-right: auto;

    .playerSection {
        /* 2 */
        margin-top: 0;
        width: 100%;
        height: 14vh;

        .winnerName {
            margin: auto;
            text-align: center;
            font-size: 6vh;
            font-family: "Gagalin";
            color: white;

            &.large {
                font-size: 6vh;
            }

            &.medium {
                font-size: 4vh;
            }

            &.small {
                font-size: 2.5vh;
            }
        }

        .number {
            font-size: 5vh;
        }

        .potato {
            height: 14vh;
            top: 2vh;

            &.left {
                left: -6vh;
            }

            &.right {
                right: -6vh;
            }
        }
    }
}


.player {
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    position: relative;

    &.shrink {
        transform: scale(0.8);
    }

    &.hidden {
        opacity: 0;
    }

    &:nth-last-child(n+1):first-child, &:nth-last-child(n+1):first-child ~ .player {
        &.winner {
            @include largeWin;
        }
    }


    &:nth-last-child(n+2):first-child,
    &:nth-last-child(n+2):first-child ~ .player {
        &.winner {
            @include largeWin;
        }
    }

    &:nth-last-child(n+3):first-child,
    &:nth-last-child(n+3):first-child ~ .player {
        &:nth-child(1) {
            &.winner {
                @include largeWin;
            }
        }

        &:nth-child(2), &:nth-child(3) {
            &.winner {
                @include mediumWin;
            }
        }
    }

    &:nth-last-child(n+4):first-child,
    &:nth-last-child(n+4):first-child ~ .player {
        &:nth-child(n+1) {
            &.winner {
                @include mediumWin;
            }
        }
    }

    &:nth-last-child(n+5):first-child,
    &:nth-last-child(n+5):first-child ~ .player {

        &:nth-child(n+1) {
            &.winner {
                @include mediumWin;
            }
        }

        &:nth-child(5) {
            &.winner {
                @include largeWin;
            }
        }
    }

    &:nth-last-child(n+6):first-child,
    &:nth-last-child(n+6):first-child ~ .player {

        &:nth-child(n+1) {
            &.winner {
                @include mediumWin;
            }
        }
    }

    &:nth-last-child(n+7):first-child,
    &:nth-last-child(n+7):first-child ~ .player {

        &:nth-child(n+1) {
            &.winner {
                @include smallWin;
            }
        }

        &:nth-child(7) {
            &.winner {
                @include mediumWin;
            }
        }
    }

    &:nth-last-child(n+8):first-child,
    &:nth-last-child(n+8):first-child ~ .player {

        &:nth-child(n+1) {
            &.winner {
                @include smallWin;
            }
        }

        &:nth-child(7), &:nth-child(8) {
            &.winner {
                @include mediumWin;
            }
        }
    }

    &.reverse {
        flex-direction: row-reverse;
    }

    .spectatingText {
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: -5vh;
        font-size: 3vh;
        text-align: center;
        font-family: "Helenita";
    }

    .playerSection {
        position:relative;
        z-index: 5;
        height: 17.5vh;
        width: 17.5vh;
        border: solid 0.6vh #1D1D1B;
        border-radius: 2vh;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;
        box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.5), 0 0 0 1px #468A90;

        .number {
            font-family: "Gagalin";
            font-size: 8vh;
            position: absolute;
            margin: auto;
            color: black;
            top: 0;

            &.left {
                left: 2vh;
            }

            &.right {
                right: 2vh;
            }
        }



        .potato {
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 15vh;
            margin: auto;
            position: absolute;
            top: -3vh;
            z-index: 1;

            &.left {
                left: -4.5vh;
            }

            &.right {
                right: -4.5vh;
            }

            &.hidden {
                opacity: 0;
            }

            &.fade {
                opacity: 0.3;
            }
        }

        .countdownContainer {
            height: 14.5vh;
            margin: auto;
            position: absolute;
            left: 1.75vh;
            top: 1.5vh;
            font-family: "Interstate-Regular";
            font-size: 2vh;
            text-align: center;
        }
    }

    .textSection {
        position: absolute;
        width: 17.5vh;
        height: 30%;
        margin: auto;
        bottom: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        background-color: #1D1D1B;
        border-bottom-left-radius: 2vh;
        border-bottom-right-radius: 2vh;
        padding-bottom: 0.5vh;
        z-index: 6;

        .nameText {
            font-family: "Interstate";
            font-size: 2.75vh;
            margin: auto;
            color: white;

            &.large {
                font-size: 3.5vh;
            }

            &.medium {
                font-size: 2.75vh;
            }

            &.small {
                font-size: 2vh;
            }
        }
    }


    .answeredText {
        color: #EC0080;
        text-align: center;
        top: -6.5vh;
        margin: auto;
        font-size: 4.25vh;
        position: absolute;
        opacity: 0;
        z-index: -1;
        font-family: "Gagalin";
        left: 0;
        right: 0;
        transition: 1s;
        transform: translateY(5vh);

        &.show {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
