div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

@mixin largeWin {
    height: unset;
    width: 80%;
    margin: 2vh;
    margin-left: auto;
    margin-right: auto;
    border: solid 0.6vh #1D1D1B;
    border-radius: 2vh;
    display: flex;
    position: relative;
    padding-top: 1vh;
    padding-bottom: 1vh;
    transition: 0.5s;
    transform: scale(0);

    &.show {
        transform: scale(1);
    }

    .winnerName {
        margin: auto;
        text-align: center;
        font-family: "Gagalin";
        color: white;
        font-size: 9vh;

        &.large {
            font-size: 11.5vh;
        }

        &.medium {
            font-size: 8vh;
        }

        &.small {
            font-size: 6vh;
        }
    }

    .number {
        font-size: 10vh;
        font-family: "Gagalin";
        position: absolute;
        margin: auto;
        color: black;
        top: 0;

        &.left {
            left: 2vh;
        }

        &.right {
            right: 2vh;
        }
    }

    .potato {
        height: 32vh;
        top: -12vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto;
        position: absolute;
        z-index: 1;

        &.left {
            right: -13vh;
        }

        &.right {
            left: -13vh;
        }
    }
}

@mixin mediumWin {
    height: unset;
    width: 45%;
    margin: 2vh;
    margin-left: auto;
    margin-right: auto;
    border: solid 0.6vh #1D1D1B;
    border-radius: 2vh;
    display: flex;
    padding-top: 0.75vh;
    padding-bottom: 0.75vh;

    .winnerName {
        margin: auto;
        text-align: center;
        font-size: 6vh;
        font-family: "Gagalin";
        color: white;

        &.large {
            font-size: 8vh;
        }

        &.medium {
            font-size: 6vh;
        }

        &.small {
            font-size: 4vh;
        }
    }

    .number {
        font-family: "Gagalin";
        font-size: 6vh;
        position: absolute;
        margin: auto;
        color: black;
        top: 0;

        &.left {
            left: 2vh;
        }

        &.right {
            right: 2vh;
        }
    }

    .potato {
        height: 23.5vh;
        top: -7vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto;
        position: absolute;
        z-index: 1;

        &.left {
            right: -10vh;
        }

        &.right {
            left: -10vh;
        }
    }
}

@mixin smallWin {
    height: unset;
    width: 31%;
    margin: 2vh;
    margin-left: auto;
    margin-right: auto;
    border: solid 0.6vh #1D1D1B;
    border-radius: 2vh;
    display: flex;
    padding-top: 1vh;
    padding-bottom: 1vh;

    .winnerName {
        margin: auto;
        text-align: center;
        font-size: 4vh;
        font-family: "Gagalin";
        color: white;

        &.large {
            font-size: 6vh;
        }

        &.medium {
            font-size: 4vh;
        }

        &.small {
            font-size: 2.5vh;
        }
    }

    .number {
        font-size: 4vh;
        font-family: "Gagalin";
        position: absolute;
        margin: auto;
        color: black;
        top: 0;

        &.left {
            left: 2vh;
        }

        &.right {
            right: 2vh;
        }
    }

    .potato {
        height: 16vh;
        top: -4vh;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin: auto;
        position: absolute;
        z-index: 1;

        &.left {
            right: -5vh;
        }

        &.right {
            left: -5vh;
        }
    }
}

.logoSection {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: fit-content;
    width: fit-content;
    transition: 1s;

    &.topLeft {
        transform: scale(0.4);
        top: -75vh;
        left: -75vw;
    }

    .logo {
        height: 35vh;
        margin-right: 1vh;
        margin-top: auto;
        margin-bottom: auto;
    }

    .logo17 {
        position:absolute;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
        height: 10vh;
        right:-40%;
        //bottom:50%;
        
        &.show {
            opacity: 1;
        }
    }
}

.gameContainer {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: #FFF100;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    background-image: url(../images/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-in-out, background-color 0.5s ease-in-out;
    overflow: hidden;
    z-index: 0;

    &.adults {
        background-image: url(../images/background-green.png);
        background-color: #21d31c;
    }

    .loadingContainer {
        position: absolute;
        left: 0;
        right: 0;
        /*top: 0;*/
        /* bottom: 0; */
        margin: auto;
        max-width: 700px !important;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        z-index: 1;
    }

    .doodleSection {
        position: absolute;
        width: 40%;
        height: 50%;
        margin: auto;
        left: 0;
        bottom: 0;
        top: 0;

        .doodle {
            height: 75%;
            width: auto;
            position: absolute;
            margin: auto;
        }

        &.right {
            left: unset;
            right: 0;
        }
    }

    .bannerContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        z-index: 20;
        touch-action: none;
        user-select: none;


        @keyframes bannerAnim {
            0% {
                transform: translate(-220vw, -10vh);
            }

            15% {
                transform: translate(-60vw, 0vh);
            }


            85% {
                transform: translate(-60vw, 0vh);
            }

            100% {
                transform: translate(220vw, 10vh);
            }
        }

        &.play {

            .bannerBg {
                animation-name: bannerAnim;
                animation-duration: 5s;
                animation-iteration-count: 1;
            }

            .bannerInfo {
                animation-name: bannerTextAnim;
                animation-duration: 5s;
                animation-iteration-count: 1;
            }
        }

        .bannerBg {
            width: 200%;
            height: 100%;
            position: absolute;
            transform: translate(-220vw, -10vh);
        }

        .bannerInfo {
            width: 75%;
            height: fit-content;
            margin: auto;
            z-index: 1;
            transform: translate(-100vw, -10vh) rotate(6deg);
            transition: linear;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;

            .bannerTitle {
                font-family: "Gagalin";
                font-size: 15vh;
                text-align: center;
                width: 85%;
                color: white;
            }
        }

        @keyframes bannerTextAnim {
            0% {
                transform: translate(-100vw, -10vh) rotate(6deg);
            }

            15% {
                transform: translate(-100vw, -10vh) rotate(6deg);
            }

            35% {
                transform: translate(0, 0) rotate(6deg);
            }


            80% {
                transform: translate(0, 0) rotate(6deg);
            }

            100% {
                transform: translate(100vw, 10vh) rotate(6deg);
            }
        }
    }


    .counterSection {
        width: fit-content;
        height: fit-content;
        margin: auto;
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 0;
        right: 0;
        top: 2vh;

        &.hidden {
            opacity: 0;
        }

        .counterText {
            text-align: center;
            font-size: 5vh;
            font-family: "KCHand";
            margin-left: auto;
        }

        .tickList {
            width: fit-content;
            margin-right: auto;
            display: flex;
        }
    }

    .roomCode {
        position: absolute;
        right: 3vh;
        top: 1vh;
        margin: auto;
        display: flex;
        flex-direction: row;

        .textBox {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            height: fit-content;

            .text {
                text-align: end;
                font-size: 3.5vh;
                font-family: "Helenita";
                font-weight: bold;

                &:nth-child(1) {
                    transform: translateY(0.75vh);
                }

                &:nth-child(2) {
                    transform: translateY(-0.75vh);
                }

                &.code {
                    font-family: "Gagalin";
                    font-size: 4vh;
                    font-weight: normal;
                }
            }

            .iconsBox {
                margin-left: auto;
                margin-top: auto;
                margin-bottom: auto;
                display: flex;
                flex-direction: row;
                width: fit-content;
                height: fit-content;
                z-index: 1000;

                .muteToggle {
                    width: fit-content;
                    height: fit-content;
                    position: relative;
                    margin: auto;
                    cursor: pointer;
                    padding-left: 1vh;

                    .muteIcon {
                        width: auto;
                        height: 5vh;
                    }
                }
            }
        }

        .qrCodeBox {
            background-color: white;
            width: fit-content;
            height: fit-content;
            margin: auto;
            padding: 1vh;
            display: flex;
            border-radius: 1vh;
            margin-left: 1vh;
            margin-top: 1vh;

            .qrCode {
                height: 14vh !important;
                width: 14vh !important;
                margin: auto;
            }
        }
    }


    .playerColumn {
        height: 100%;
        width: 20%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &.left {
        }

        &.right {
        }
    }

    .playerRow {
        height: fit-content;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        padding-left: 1vh;
        padding-right: 1vh;
        transition: 1s;

        &.hide {
            transform: translateY(30vh);
        }
    }

    .middleColumn {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;



        .awardSection {
            width: 100%;
            height: 100%;
            display: flex;
            margin: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -10vh;
            top: 0;
            transition: 1s;
            transform: scale(0);

            &.show {
                transform: scale(1);
            }

            &.dissapear {
                transform: scale(1) translateY(100vh);
            }

            .frameSection {
                width: fit-content;
                height: fit-content;
                margin: auto;
                display: flex;
                position: relative;

                .frameItem {
                    width: 53vh;
                    height: 53vh;
                    position: absolute;
                    margin: auto;
                    display: flex;
                    background-color: white;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 2;

                    .itemContent {
                        width: 100%;
                        margin: auto;
                        font-size: 5vh;
                        text-align: center;
                        border-radius: inherit;

                        &.text {
                            font-family: "Helenita";
                            width: 90%;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            overflow-wrap: break-word;
                        }
                    }
                }

                .frame {
                    margin: auto;
                    width: 80vh;
                    height: 80vh;
                    margin-bottom: 1vh;
                    z-index: 3;
                }
            }

            .playerSection {
                position: absolute;
                margin: auto;
                left: 68vw;
                bottom: -10vh;
                width: fit-content;
                height: fit-content;
                display: flex;
                flex-direction: column;
                z-index: 1;

                .potato {
                    height: 45vh;
                    width: 45vh;
                    margin: auto;
                    transition: 0.5s;
                    transform: translate(-50vh, -10vh);

                    &.popout {
                        transform: translate(-10vh, -10vh) rotate(7.5deg);
                    }
                }
            }

            .potName {
                font-size: 8vh;
                font-family: "Gagalin";
                text-align: center;
                margin: auto;
                z-index: 4;
                background-color: #E90F79;
                padding: 1vh;
                padding-left: 2vh;
                padding-right: 2vh;
                color: white;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: fit-content;
            }
        }

        .revealContainer {
            width: 100vw;
            height: fit-content;
            display: flex;
            flex-direction: row;
            position: relative;
            z-index: 10;
            margin: auto;
            margin-top: 21vh;


            .ogSection {
                margin: auto;
                margin-right: 1vw;
                display: flex;
                flex-direction: column;
                width: fit-content;
                height: fit-content;
                z-index: 2;
                /*padding-top: 3vh;*/
                border-radius: 2vh;
                background-color: #EA0C7B;
                max-width: 45vh;
                box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
                position: relative;
                transition: 1s;

                &.hide {
                    transform: translateX(-60vh);
                }

                .ogTitle {
                    font-family: "Gagalin";
                    font-size: 5vh;
                    text-align: center;
                    margin: auto;
                }

                .ogCard {
                    width: 100%;
                    margin: auto;
                    padding: 1vh;
                    font-size: 3.75vh;
                    font-family: "Helenita";
                    text-align: center;
                    color: white;
                    overflow-wrap: break-word;
                    padding-bottom: unset;
                }

                .revealingPotato {
                    width: 40vh;
                    height: 40vh;
                    margin-left: auto;
                    margin-right: auto;
                }

                .revealingName {
                    font-family: "Gagalin";
                    font-size: 4vh;
                    text-align: center;
                    color: white;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 1vh;
                    padding-bottom: 5vh;
                    padding-top: unset;
                }

                .revealList {
                    position: absolute;
                    width: 20vw;
                    height: fit-content;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;

                    .revealItem {
                        width: 20vw;
                        height: 20vw;
                        position: absolute;
                        display: flex;
                        border-radius: 2vh;
                        background-color: white;
                        box-shadow: unset;
                        box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
                        transition: 0.5s;
                        transform: translateY(50vh);

                        &.reveal {
                            transform: translateY(-10vh) scale(1.25);
                        }

                        &.hide {
                            transform: translateY(-10vh) scale(0);
                        }

                        &.empty {
                            background-color: red !important;
                        }

                        .itemContent {
                            width: 100%;
                            margin: auto;
                            font-size: 5.25vh;
                            text-align: center;
                            border-radius: inherit;

                            &.text {
                                font-family: "Helenita";
                                /*height: 50%;*/
                                width: 90%;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                overflow-wrap: break-word;
                            }

                            &.empty {
                                font-family: "Gagalin";
                                font-size: 10vh;
                                color: white;
                                text-align: center;
                                height: fit-content;
                                width: 90%;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                            }
                        }
                    }
                }
            }



            .storyList {
                width: 70vw;
                height: 34vw;
                display: flex;
                flex-direction: row;
                margin-left: auto;
                margin-right: auto;
                flex-wrap: wrap;
                margin-bottom: auto;
                margin-top: 5vh;
                transition: 1s;

                &.bigger {
                    transform: scale(1.15) translateX(-13vw);
                }

                &.hide {
                    transform: scale(1.15) translate(-13vw, 70vh);
                }

                .backdrop {
                    position: absolute;
                    width: 100vw;
                    height: 100vh;
                    background-color: black;
                    opacity: 0;
                    z-index: 1;
                    left: 0;
                    margin: auto;
                    top: 0;
                    /*transition: all 1s linear;*/

                    &.hide {
                        opacity: 0;
                    }
                }

                .listItem {
                    width: 15vw;
                    height: 15vw;
                    position: relative;
                    display: flex;
                    border-radius: 2vh;
                    background-color: white;
                    box-shadow: unset;
                    margin: auto;
                    box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
                    transition: 0.5s;
                    opacity: 0;
                    transform: scale(0);

                    &.show {
                        opacity: 1;
                        transform: scale(1);
                    }

                    &.bigger {
                        transform: scale(1.75);
                        z-index: 2;
                    }

                    &.smaller {
                        transform: scale(1);
                        z-index: 0;
                    }
                    /* &:nth-child(1) {
                        transform: scale(1.75) translate(-14vw, 7vw);
                    }

                    &:nth-child(2) {
                        transform: scale(1.75) translate(-23.7vw, 7vw);
                    }

                    &:nth-child(3) {
                        transform: scale(1.75) translate(-33.4vw, 7vw);
                    }

                    &:nth-child(4) {
                        transform: scale(1.75) translate(-43.1vw, 7vw);
                    }

                    &:nth-child(5) {
                        transform: scale(1.75) translate(-14vw, -2.7vw);
                    }

                    &:nth-child(6) {
                        transform: scale(1.75) translate(-23.7vw, -2.7vw);
                    }

                    &:nth-child(7) {
                        transform: scale(1.75) translate(-33.4vw, -2.7vw);
                    }

                    &:nth-child(8) {
                        transform: scale(1.75) translate(-43.1vw, -2.7vw);
                    }*/
                    /*&:last-child {
                        box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
                    }*/
                    &.empty {
                        background-color: red !important;
                        /*box-shadow: unset !important;*/
                    }
                    /*&.revealed {*/
                    /*width: 21vh;
                height: 21vh;*/
                    /*transition: all 1s linear;
                        transform: scale(1) translate(0, 0);
                    }*/

                    .mask {
                        width: 100%;
                        height: 100%;
                        border-radius: inherit;
                        background-color: inherit;
                        position: absolute;
                        margin: auto;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        display: flex;
                        transition: 0.25s;

                        &.hide {
                            opacity: 0;
                        }

                        .maskLogo {
                            width: 50%;
                            height: auto;
                            margin: auto;
                        }
                    }

                    .itemContent {
                        width: 100%;
                        margin: auto;
                        font-size: 4vh;
                        text-align: center;
                        border-radius: inherit;

                        &.text {
                            font-family: "Helenita";
                            /*height: 50%;*/
                            width: 90%;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            overflow-wrap: break-word;
                        }

                        &.empty {
                            font-family: "Gagalin";
                            font-size: 10vh;
                            color: white;
                            text-align: center;
                            height: fit-content;
                            width: 90%;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                        }
                    }
                }
            }
        }

        .timerSection {
            position: absolute;
            margin: auto;
            top: 15vh;
            display: flex;
            flex-direction: row;
            right: 8vh;
            width: fit-content;
            transition: 1s;

            .timerBg {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 2vh;
                width: 26vh;
                height: 65%;
                background-color: #E90F79;
                border-top-left-radius: 2vh;
                border-bottom-left-radius: 2vh;
            }

            .timer {
                font-size: 7vh;
                font-family: 'Gagalin';
                text-align: center;
                position: absolute;
                top: 5.5vh;
                left: 15vh;
                color: white;
            }

            &.hideTimer {
                transform: translateX(26vh);
            }
        }

        .roundSection {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            transition: 1s;

            &.draw {
                transform: translateX(-100vw);
            }

            &.write {
                transform: translateX(100vw);
            }

            &.cards {
                transform: translateY(-100vh);
            }

            &.show {
                transform: translate(0, 0);
            }


            .roundTitle {
                width: 77vh;
                text-align: center;
                font-size: 6vh;
                font-family: "Gagalin";
                margin: auto;
                margin-top: 15vh;
            }

            .cardSection {
                height: fit-content;
                width: fit-content;
                position: absolute;
                margin: auto;
                left: 0;
                bottom: 10vh;
                right: 0;

                .cards {
                    width: 30vh;
                    height: auto;
                }

                .sampleCard {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 20vh;
                    height: 30vh;
                    border-radius: 2vh;
                    /*border: 0.5vh solid black;*/
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    /*box-shadow: -0.5vh 0.5vh 0px 0.25vh rgba(0,0,0,0.5);*/

                    &.one {
                        transform: translateX(-8vh) rotate(-6deg);
                    }

                    &.two {
                        transform: translateX(8vh) rotate(3deg);
                    }

                    .half {
                        width: 100%;
                        height: 50%;
                        border-left: 0.5vh solid black;
                        border-right: 0.5vh solid black;
                        /*box-shadow: 1px 1px 1px transparent;*/
                        box-shadow: -0.5vh 0.5vh 0px 0.25vh rgba(0,0,0,0.5);

                        &.top {
                            border-top-left-radius: 2vh;
                            border-top-right-radius: 2vh;
                            border-top: 0.5vh solid black;

                            &.one {
                                background-color: white;
                            }

                            &.two {
                                background-color: black;
                            }
                        }

                        &.bottom {
                            border-bottom-left-radius: 2vh;
                            border-bottom-right-radius: 2vh;
                            border-bottom: 0.5vh solid black;

                            &.one {
                                background-color: #FFED00;
                            }

                            &.two {
                                background-color: #E60076;
                            }
                        }
                    }
                }
            }

            .painters {
                height: fit-content;
                width: fit-content;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                bottom: 5vh;
                display: flex;
                flex-direction: row;
                gap: 5vh;
                justify-content: center;
                align-items: center;
            }
        }



        .winnersSection {
            width: 100%;
            height: 90%;
            display: flex;
            margin: auto;
            margin-bottom: 1vh;
            flex-direction: column;

            .winnersText {
                margin: auto;
                text-align: center;
                font-size: 12vh;
                font-family: 'Gagalin';
                margin-bottom: 1vh;
            }

            .winnersBox {
                width: 65%;
                height: fit-content;
                margin: auto;
                /*margin-top: unset;*/
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-content: space-between;

                .winner {
                    &:nth-last-child(n+1):first-child, &:nth-last-child(n+1):first-child ~ .winner {
                        @include largeWin;
                    }


                    &:nth-last-child(n+2):first-child,
                    &:nth-last-child(n+2):first-child ~ .winner {
                        @include largeWin;
                    }

                    &:nth-last-child(n+3):first-child,
                    &:nth-last-child(n+3):first-child ~ .winner {
                        &:nth-child(1) {
                            @include largeWin;
                        }

                        &:nth-child(2), &:nth-child(3) {
                            @include mediumWin;
                        }
                    }

                    &:nth-last-child(n+4):first-child,
                    &:nth-last-child(n+4):first-child ~ .winner {
                        &:nth-child(n+1) {
                            @include mediumWin;
                        }
                    }

                    &:nth-last-child(n+5):first-child,
                    &:nth-last-child(n+5):first-child ~ .winner {

                        &:nth-child(n+1) {
                            @include mediumWin;
                        }

                        &:nth-child(5) {
                            @include largeWin;
                        }
                    }

                    &:nth-last-child(n+6):first-child,
                    &:nth-last-child(n+6):first-child ~ .winner {

                        &:nth-child(n+1) {
                            @include mediumWin;
                        }
                    }

                    &:nth-last-child(n+7):first-child,
                    &:nth-last-child(n+7):first-child ~ .winner {

                        &:nth-child(n+1) {
                            @include smallWin;
                        }

                        &:nth-child(7) {
                            @include mediumWin;
                        }
                    }

                    &:nth-last-child(n+8):first-child,
                    &:nth-last-child(n+8):first-child ~ .winner {

                        &:nth-child(n+1) {
                            @include smallWin;
                        }

                        &:nth-child(7), &:nth-child(8) {
                            @include mediumWin;
                        }
                    }
                }
            }
        }

        .startGameSection {
            width: 100%;
            height: fit-content;
            margin: auto;
            display: flex;
            flex-direction: column;
            transition: 1s;
            transform: translateY(100vh);
            position: absolute;
            top: 25vh;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 25;

            &.show {
                transform: translateY(0);
            }

            .button {
                width: fit-content;
                height: fit-content;
                margin: auto;
                font-family: "Gagalin";
                font-size: 7vh;
                text-align: center;
                background-color: #E90F79;
                border-radius: 1.5vh;
                color: white;
                padding: 3vh;
                padding-left: 5vh;
                padding-right: 5vh;
                cursor: pointer;
                box-shadow: 0px 1vh 0px 0px #a3195b;
                user-select: initial !important;
                cursor: pointer;

                &.smaller {
                    width: fit-content;
                    font-size: 4vh;
                    margin-top: 4vh;
                    padding-top: 2vh;
                    padding-bottom: 2vh;
                    border-radius: 1vh;
                    box-shadow: 0px 0.75vh 0px 0px #a3195b;
                }
            }

            .skipBox {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                width: fit-content;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: 3vh;

                input[type="checkbox"] {
                    -webkit-appearance: none;
                    appearance: none;
                    background-color: #E90F79;
                    margin: 0;
                    font: inherit;
                    width: 7.5vh;
                    height: 7.5vh;
                    border-radius: 2vh;
                    transform: translateY(-0.075em);
                    display: grid;
                    place-content: center;

                    &:hover {
                        background-color: #A3195B !important;
                    }
                }

                input[type="checkbox"]::before {
                    content: "";
                    width: 5.5vh;
                    height: 5.5vh;
                    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                    transform: scale(0);
                    transform-origin: center;
                    transition: 120ms transform ease-in-out;
                    box-shadow: inset 1em 1em var(--form-control-color);
                    /* Windows High Contrast Mode */
                    background-color: white;
                }

                input[type="checkbox"]:checked::before {
                    transform: scale(1);
                }

                label[for="checkbox"] {
                    padding-left: 2vh;
                    font-size: 5.5vh;
                    border-radius: 2vh;
                    font-family: "Helenita";
                    color: #212529;
                    margin: auto;
                }
            }

            .contentFilter {
                width: 35vh;
                margin: 1.5vh auto;
                margin-top: 10vh;
                height: 5vh;
                /*font-size: 0;*/
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;
                position: relative;

                .bar {
                    position: absolute;
                    top: calc(100% / 2 - 0.75vh);
                    height: 1.75vh;
                    left: 3vh;
                    width: calc(100% - 6vh);
                    background-color: #6f123e;
                    border-radius: 1vh;
                    -webkit-appearance: none;
                    appearance: none;
                    cursor: pointer;
                    outline: none;
                    box-shadow: -0.5vh -0.5vh 0.5vh -0.1vh rgba(255,255,255,0.3) inset, 0.5vh 0.5vh 0.5vh 0px rgba(0,0,0,0.3) inset, 0px 0px 0.3vh 0.1vh rgba(0,0,0,0.3);
                }

                .icons {
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    top: -5vh;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    margin: 0;

                    img {
                        //left: 50%;
                        transform: translate(0, -10vh);
                        z-index: 1;
                        height: 15vh;
                        cursor: pointer;
                        margin: 0;
                        -webkit-user-drag: none;
                    }
                }

                .bar::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    height: 5vh;
                    width: 5vh;
                    background-color: #E90F79;
                    border-radius: 50%;
                    box-shadow: -0.5vh -0.5vh 0.5vh -0.1vh rgba(0,0,40,0.3) inset, 0.5vh 0.5vh 0.5vh 0px rgba(255,255,255,0.5) inset, 0px 0px 0.3vh 0.1vh rgba(0,0,0,0.3);
                }
            }
        }

        .playAgainSection {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
            margin-bottom: unset;
            position: absolute;
            transform: translateY(100vh);
            transition: 1s;
            z-index: 25;

            &.show {
                transform: translateY(0);
            }

            .buttonsSection {
                width: 35%;
                height: fit-content;
                margin: auto;
                display: flex;
                flex-direction: column;
                margin-top: 32.5vh;
                margin-left: 15%;

                .button {
                    width: fit-content;
                    height: fit-content;
                    margin: auto;
                    font-family: "Gagalin";
                    font-size: 7vh;
                    text-align: center;
                    background-color: #E90F79;
                    border-radius: 1.5vh;
                    color: white;
                    padding: 3vh;
                    padding-left: 5vh;
                    padding-right: 5vh;
                    cursor: pointer;
                    box-shadow: 0px 1vh 0px 0px #a3195b;
                    user-select: initial !important;
                    cursor: pointer;

                    &.smaller {
                        width: fit-content;
                        font-size: 4vh;
                        margin-top: 4vh;
                        padding-top: 2vh;
                        padding-bottom: 2vh;
                        border-radius: 1vh;
                        box-shadow: 0px 0.75vh 0px 0px #a3195b;
                    }
                }
            }

            .leaderboardSection {
                width: 50%;
                height: 75%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                margin-top: auto;
                justify-content: center;
                align-items: start;
                padding-left: 5vh;

                .boardRow {
                    width: 70%;
                    height: fit-content;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    border-radius: 1vh;
                    position: relative;
                    margin-bottom: 1.25vh;
                    margin-top: 1.25vh;

                    .numberBox {
                        color: white;
                        background-color: black;
                        width: 10%;
                        min-height: 100%;
                        display: flex;
                        border-top: solid black 0.5vh;
                        border-bottom: solid black 0.5vh;
                        border-left: solid black 0.5vh;
                        border-top-left-radius: 1vh;
                        border-bottom-left-radius: 1vh;

                        .positionText {
                            text-align: center;
                            /*line-height: 1;*/
                            font-family: "Gagalin";
                            font-size: 3vh;
                            margin: auto;
                        }
                    }

                    .nameBox {
                        text-align: start;
                        /*line-height: 1;*/
                        font-family: "Gagalin";
                        width: 60%;
                        font-size: 3vh;
                        border-top: solid black 0.5vh;
                        border-bottom: solid black 0.5vh;
                        padding-left: 1vh;
                    }

                    .scoreBox {
                        width: 30%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        border-top: solid black 0.5vh;
                        border-bottom: solid black 0.5vh;
                        border-right: solid black 0.5vh;
                        border-top-right-radius: 1vh;
                        border-bottom-right-radius: 1vh;

                        .text {
                            font-family: "Gagalin";
                            font-size: 3vh;

                            &.smaller {
                                font-size: 2vh;
                                padding-bottom: 0.3vh;
                                padding-left: 0.5vh;
                                margin-top: auto;
                            }
                        }
                    }

                    .potato {
                        width: 10vh;
                        height: auto;
                        margin: auto;
                        position: absolute;
                        right: -6.5%;
                        top: -3.5vh;
                    }
                }
            }
        }
    }

    .title {
        position: absolute;
        margin: auto;
        top: 35vh;
        right: -121vh;
        left: 0;
        width: 65vh;
        display: flex;
        font-size: 5vh;
        font-family: "Gagalin";
        text-align: left;
    }
    /*.storyList {
        width: 100%;
        height: 100%;
        position: relative;
        margin: auto;
        margin-bottom: unset;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        .listItem {
            width: 50vh;
            height: 50vh;
            position: absolute;
            margin: auto;
            display: flex;
            border-radius: 2vh;
            transition: all 1s linear;
            background-color: white;
            box-shadow: unset;
            bottom: 25vh;
            left: 0;
            top: 0;
            right: 0;

            &:last-child {
                box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
            }

            &.empty {
                background-color: red !important;*/
    /*box-shadow: unset !important;*/
    /*}

            &.revealed {*/
    /*width: 21vh;
                height: 21vh;*/
    /*transform: scale(0.45);
                box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);

                &:nth-last-child(-n+3):first-child,
                &:nth-last-child(-n+3):first-child ~ .revealed {*/
    /* 3 or Less */
    /*&:nth-child(1) {
                        bottom: -65vh;
                        left: -50vh;
                    }

                    &:nth-child(2) {
                        bottom: -65vh;
                        left: 0;
                    }

                    &:nth-child(3) {
                        bottom: -65vh;
                        left: 50vh;
                    }
                }

                &:nth-last-child(n+4):first-child,
                &:nth-last-child(n+4):first-child ~ .revealed {*/
    /* 4 or More */
    /*&:nth-child(1) {
                        bottom: -65vh;
                        left: -75vh;
                    }

                    &:nth-child(2) {
                        bottom: -65vh;
                        left: -25vh;
                    }

                    &:nth-child(3) {
                        bottom: -65vh;
                        left: 25vh;
                    }

                    &:nth-child(4) {
                        bottom: -65vh;
                        left: 75vh;
                    }
                }

                &:nth-last-child(n+5):first-child,
                &:nth-last-child(n+5):first-child ~ .revealed {*/
    /* 5 or More */
    /*&:nth-child(1) {
                        bottom: -65vh;
                        left: -100vh;
                    }

                    &:nth-child(2) {
                        bottom: -65vh;
                        left: -50vh;
                    }

                    &:nth-child(3) {
                        bottom: -65vh;
                        left: 0vh;
                    }

                    &:nth-child(4) {
                        bottom: -65vh;
                        left: 50vh;
                    }

                    &:nth-child(5) {
                        bottom: -65vh;
                        left: 100vh;
                    }
                }

                &:nth-last-child(n+6):first-child,
                &:nth-last-child(n+6):first-child ~ .revealed {*/
    /* 6 or More */
    /*&:nth-child(1) {
                        bottom: -65vh;
                        left: -125vh;
                    }

                    &:nth-child(2) {
                        bottom: -65vh;
                        left: -75vh;
                    }

                    &:nth-child(3) {
                        bottom: -65vh;
                        left: -25vh;
                    }

                    &:nth-child(4) {
                        bottom: -65vh;
                        left: 25vh;
                    }

                    &:nth-child(5) {
                        bottom: -65vh;
                        left: 75vh;
                    }

                    &:nth-child(6) {
                        bottom: -65vh;
                        left: 125vh;
                    }
                }

                &:nth-last-child(n+7):first-child,
                &:nth-last-child(n+7):first-child ~ .revealed {*/
    /* 7 or More */
    /*&:nth-child(1) {
                        bottom: -65vh;
                        left: -150vh;
                    }

                    &:nth-child(2) {
                        bottom: -65vh;
                        left: -100vh;
                    }

                    &:nth-child(3) {
                        bottom: -65vh;
                        left: -50vh;
                    }

                    &:nth-child(4) {
                        bottom: -65vh;
                        left: 0vh;
                    }

                    &:nth-child(5) {
                        bottom: -65vh;
                        left: 50vh;
                    }

                    &:nth-child(6) {
                        bottom: -65vh;
                        left: 100vh;
                    }

                    &:nth-child(7) {
                        bottom: -65vh;
                        left: 150vh;
                    }
                }

                &:nth-last-child(n+8):first-child,
                &:nth-last-child(n+8):first-child ~ .revealed {*/
    /* 8 or More */
    /*&:nth-child(1) {
                        bottom: -65vh;
                        left: -175vh;
                    }

                    &:nth-child(2) {
                        bottom: -65vh;
                        left: -125vh;
                    }

                    &:nth-child(3) {
                        bottom: -65vh;
                        left: -75vh;
                    }

                    &:nth-child(4) {
                        bottom: -65vh;
                        left: -25vh;
                    }

                    &:nth-child(5) {
                        bottom: -65vh;
                        left: 25vh;
                    }

                    &:nth-child(6) {
                        bottom: -65vh;
                        left: 75vh;
                    }

                    &:nth-child(7) {
                        bottom: -65vh;
                        left: 125vh;
                    }

                    &:nth-child(8) {
                        bottom: -65vh;
                        left: 175vh;
                    }
                }
            }

            .itemContent {
                width: 100%;
                margin: auto;
                font-size: 6vh;
                text-align: center;
                border-radius: inherit;

                &.text {
                    font-family: "Helenita";*/
    /*height: 50%;*/
    /*width: 90%;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    overflow-wrap: break-word;
                }

                &.empty {
                    font-family: "Gagalin";
                    font-size: 14.5vh;
                    color: white;
                    text-align: center;
                    height: fit-content;
                    width: 90%;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                }
            }
        }

        .itemInfo {
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            top: -4vh;
            flex-direction: row;
            background-color: black;
            width: fit-content;
            padding-left: 1vh;
            padding-right: 1vh;
            border-radius: 1vh;

            .name {
                font-size: 2.5vh;
                text-align: center;
                font-family: "Helenita";
                color: white;
            }
        }
    }

    .ogSection {
        position: absolute;
        margin: auto;
        left: 12vh;
        top: 0;
        bottom: 27vh;
        display: flex;
        flex-direction: column;
        width: fit-content;
        height: fit-content;

        .ogTitle {
            font-family: "Gagalin";
            font-size: 5vh;
            text-align: center;
            margin: auto;
        }

        .ogCard {
            box-shadow: 7px 8px 16px 3px rgba(0,0,0,0.3);
            width: 100%;
            margin: auto;
            padding: 2vh;
            font-size: 4vh;
            font-family: "Helenita";
            text-align: center;
            border-radius: 2vh;
            background-color: #EA0C7B;
            color: white;
            max-width: 50vh;
        }
    }*/



    .playerSpot {
        margin: auto;
        right: 0;
        top: 10vh;
        left: 76vh;
        display: flex;
        position: absolute;
        flex-direction: column;
    }

    .votesSection {
        width: 80%;
        height: 100%;
        margin: auto;
        display: flex;
        flex-direction: row;

        .voteContainer {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .voteTitle {
                margin-top: 1%;
                width: 100%;
                padding: 5%;
                text-align: center;
                font-size: 5vh;
            }

            .voteList {
                width: 90%;
                margin: auto;
                display: flex;
                flex-direction: row;

                .voteSpot {
                    margin: auto;
                    display: flex;
                    flex-direction: column;

                    .potato {
                        width: 20vh;
                        height: 20vh;
                        margin-top: auto;
                        margin-left: auto;
                        margin-right: auto;
                    }

                    .name {
                        font-size: 4vh;
                        text-align: center;
                        margin-top: 1vh;
                        margin-bottom: auto;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}
