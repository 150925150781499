
@font-face {
    font-family: "Gagalin";
    src: url(./fonts/gagalin.ttf) format('truetype');
}
@font-face {
    font-family: "Helenita";
    src: url('./fonts/HelenitaBookRough.otf') format('opentype');
}
@font-face {
    font-family: "Interstate";
    src: local('Asap'), url(./fonts/Interstate-Regular.otf) format('opentype');
}
@font-face {
    font-family: "MyriadPro";
    src: local('Asap'), url(./fonts/MyriadPro-Regular.otf) format('opentype');
}