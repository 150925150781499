.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;

    .logoBox {
        position:relative;

        .logo {
            height: 7vh;
            margin-left: 3vh;
            margin-right: auto;
            margin-top: 1vh;

            &.primary {
                height: 15vh;
                margin: 0;
            }
        }

        .logo17 {
            position: absolute;
            opacity: 0;
            transition: opacity 0.5s ease-in-out;
            height: 5vh;
            right: -3vh;
            bottom: -3.5vh;

            &.show {
                opacity: 1;
            }
        }
    }

    .title {
        height: 15%;
        font-size: 5vh;
        font-family: 'Gagalin';
        text-align: center;
        margin: auto;
        margin-bottom: unset;
        padding: 3vh;
        padding-left: unset;
        padding-right: unset;
    }

    .content {
        font-size: 5vh;
        font-family: "Helenita";
        text-align: center;
        color: black;
        margin: auto;
        margin-top: unset;
    }

    .playerSection {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;

        .potato {
            height: 25vh;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            user-select: all;
        }

        .text {
            font-size: 6vh;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            color: black;
            font-family: Helenita;
        }
    }

    .doodleSection {
        width: 85%;
        height: fit-content;
        margin: auto;
        display: flex;
        position: relative;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 2vh;
        justify-content: center;
        /*align-items: center;*/

        .doodleTitle {
            height: 15%;
            width: 90%;
            text-align: center;
            margin: auto;
            font-family: "Gagalin";
            font-size: 5vh;
            color: black;
        }

        .doodleCanvasContainer {
            width: fit-content;
            height: fit-content;
            position: relative;
            display: flex;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 10px;
            align-items: center;

            .doodleText {
                font-size: 35px;
                padding-left: 5%;
                padding-right: 5%;
                opacity: 0.3;
                color: black;
                text-align: center;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0%;
                height: fit-content;
                font-family: "Gagalin";
                z-index: 3;
                pointer-events: none;
            }

            .doodleBacker {
                height: 100%;
                width: 100%;
                position: absolute;
                background-color: white;
                z-index: 1;
                margin: auto;
                border-radius: inherit;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }

        .sendButtonWrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            flex: 1 0 auto;
            height: fit-content;
            align-items: center;
        }
    }

    .contentSection {
        width: 80%;
        height: 20%;
        margin: auto;
        display: flex;
        flex-direction: column;
        touch-action: none;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1.5vh;
        margin-bottom: 2vh;

        .skipBox {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: fit-content;
            justify-content: center;
            align-items: center;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 1vh;

            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #E90F79;
                margin: 0;
                font: inherit;
                width: 4vh;
                height: 4vh;
                border-radius: 1vh;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;

                &:hover {
                    background-color: #A3195B !important;
                }
            }

            input[type="checkbox"]::before {
                content: "";
                width: 2vh;
                height: 2vh;
                clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
                transform: scale(0);
                transform-origin: center;
                transition: 120ms transform ease-in-out;
                box-shadow: inset 1em 1em var(--form-control-color);
                /* Windows High Contrast Mode */
                background-color: white;
            }

            input[type="checkbox"]:checked::before {
                transform: scale(1);
            }

            label[for="checkbox"] {
                padding-left: 1vh;
                font-size: 3vh;
                font-family: 'Helenita';
                color: #212529;
                z-index: 1;
                margin-top: auto;
                margin-bottom: auto;
            }
        }

        .contentFilter {
            width: 20vh;
            margin: 0.5vh auto;
            margin-top: 11vh;
            height: 5vh;
            /*font-size: 0;*/
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            position: relative;

            .bar {
                position: absolute;
                top: calc(100% / 2 - 0.75vh);
                left: 2vh;
                height: 1vh;
                width: calc(100% - 4vh);
                background-color: #6f123e;
                border-radius: 0.75vh;
                -webkit-appearance: none;
                appearance: none;
                cursor: pointer;
                outline: none;
                box-shadow: -0.5vh -0.5vh 0.5vh -0.1vh rgba(255,255,255,0.3) inset, 0.5vh 0.5vh 0.5vh 0px rgba(0,0,0,0.3) inset, 0px 0px 0.3vh 0.1vh rgba(0,0,0,0.3);
            }

            .icons {
                display: flex;
                justify-content: space-between;
                position: relative;
                top: -5vh;
                z-index: 1;
                width: 100%;
                height: 100%;
                margin: 0;

                img {
                    //left: 50%;
                    top: 0;
                    transform: translate(0, -50%);
                    z-index: 1;
                    height: 10vh;
                    cursor: pointer;
                    margin: 0;
                    -webkit-user-drag: none;
                }
            }

            .bar::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                height: 4vh;
                width: 4vh;
                background-color: #E90F79;
                border-radius: 50%;
                box-shadow: -0.5vh -0.5vh 0.5vh -0.1vh rgba(0,0,40,0.3) inset, 0.5vh 0.5vh 0.5vh 0px rgba(255,255,255,0.5) inset, 0px 0px 0.3vh 0.1vh rgba(0,0,0,0.3);
            }
        }
    }

    .button {
        width: fit-content;
        height: fit-content;
        background-color: #E90F79;
        border-radius: 10px;
        color: white;
        padding: 5px;
        padding-left: 15px;
        padding-right: 15px;
        min-width: 40%;
        text-align: center;
        font-family: "Gagalin";
        font-size: 35px;
        box-shadow: 0px 6px 0px 0px #a3195b;
        user-select: initial !important;
        cursor: pointer;
        margin: auto;

        &.controls {
            font-size: 2.25vh;
            box-shadow: 0px 0.75vh 0px 0px #a3195b;
        }

        &.disabled {
            opacity: 0.5;
        }

        .loading {
            display: inline-block;
            width: 35px;
            height: 35px;
            border: 5px solid rgba(255,255,255,.3);
            border-radius: 50%;
            border-top-color: #fff;
            animation: spin 1s ease-in-out infinite;
            -webkit-animation: spin 1s ease-in-out infinite;
        }

        @keyframes spin {
            to {
                -webkit-transform: rotate(360deg);
            }
        }

        @-webkit-keyframes spin {
            to {
                -webkit-transform: rotate(360deg);
            }
        }
    }
}


/*.defaultSection {
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    .logo {
        height: 7vh;
        margin-left: 3vh;
        margin-right: auto;
        margin-top: 1vh;
    }

    .playerSection {
        height: fit-content;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -5vh;
        display: flex;
        flex-direction: column;

        .potato {
            height: 25vh;
            margin-left: auto;
            margin-right: auto;
        }

        .text {
            font-size: 5vh;
            font-family: "Gagalin";
            text-align: center;
            margin-left: auto;
            margin-right: auto;

            &.score {
                font-size: 4vh;
                font-family: 'Helenita';
            }
        }
    }

    .button {
        font-size: 2.5vh;
        text-align: center;
        color: #5D5D5D;
        height: fit-content;
        width: fit-content;
        padding: 0.25vh;
        padding-left: 3vh;
        padding-right: 3vh;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3vh;
        background-color: white;
        border-radius: 1vh;
        font-family: 'Gagalin';
        user-select: initial !important;
        cursor: pointer;
        transform: scale(1);

        &:active {
            transform: scale(1.2);
        }
    }

    .contentSection {
        width: 80%;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 3vh;
        display: flex;
        flex-direction: column;

        .title {
            font-size: 5vh;
            font-family: 'Gagalin';
            text-align: center;
            margin: auto;
        }

        .content {
            font-size: 3vh;
            font-family: "Helenita";
            text-align: center;
            color: #5D5D5D;
            margin: auto;
        }

        .button {
            width: fit-content;
            height: fit-content;
            margin: auto;
            background-color: #E90F79;
            border-radius: 1.5vh;
            color: white;
            padding: 1vh;
            padding-left: 2vh;
            padding-right: 2vh;
            font-family: "Gagalin";
            font-size: 4vh;
            box-shadow: 0px 1vh 0px 0px #a3195b;
            user-select: initial !important;
            cursor: pointer;
            transform: scale(1);

            &:active {
                transform: scale(1.2);
            }
        }
    }
}
*/
