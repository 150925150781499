body {
    /*overflow: hidden;*/
    background-color: #FFF100 !important;
    transition: background-color 0.5s ease-in-out;
    touch-action: double-tap-zoom;

    &:has(.adults) {
        background-color: #21d31c !important;
    }
}


:global(div) {
    touch-action: manipulation;
}

:global(img) {
    touch-action: manipulation;
}

div {
    /*transition-property: position;*/
    /*transition: all 1s ease-in-out;*/
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
}

img {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    -webkit-user-drag: none;
}

.clientContainer {
    position: absolute;
    left: 0;
    right: 0;
    /*top: 0;*/
    /* bottom: 0; */
    margin: auto;
    max-width: 600px !important;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    z-index: 1;
    background-image: url(../images/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: background-image 0.5s ease-in-out;
    /*overflow-x: hidden;*/
    /*overflow-x: hidden;*/

    &.adults {
        background-image: url(../images/background-green.png);
    }


    .connectionIssueContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        flex-direction: column;
        z-index: 10000;
        justify-content: center;
        align-items: center;

        .connectionText {
            width: fit-content;
            height: fit-content;
            font-size: 30px;
            color: white;
            font-family: "Helenita";
            text-align: center;
        }

        .refreshButton {
            font-family: 'Lucida Sans Unicode';
            padding: 10px;
            line-height: 1;
            font-weight: bolder;
            margin-left: auto;
            margin-right: auto;
            margin-top: 5%;
            width: fit-content;
            height: fit-content;
            font-size: 75px;
            color: white;
            border-radius: 20px;
            background-color: #E90F79;
            box-shadow: 0px 6px 0px 0px #A3195B;
            cursor: pointer;
        }
    }

    .pauseContainer {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        z-index: 10000;

        .pauseText {
            margin: auto;
            width: fit-content;
            height: fit-content;
            font-size: 8vh;
            color: white;
            font-family: "Gagalin";
        }
    }

    .header {
        margin-top: 1%;
        font-size: 40px;
        text-align: center;
        font-family: "Gagalin";
    }

    .roundTimer {
        position: absolute;
        margin: auto;
        right: 6vh;
        top: -2vh;
        display: flex;
        flex-direction: row;
        opacity: 0;

        &.showTimer {
            opacity: 1;
        }

        .text {
            position: absolute;
            font-size: 5.5vh;
            font-family: 'Gagalin';
            text-align: center;
            margin: auto;
            left: 9.75vh;
            top: 3vh;
        }
    }

    .title {
        font-size: 25px;
        padding: 5px;
        text-align: center;
        font-family: "Helenita";

        &.addBg {
            background-color: #EB087C;
            border-radius: 10px;
            padding: 5px;
            padding-left: 10px;
            padding-right: 10px;
            width: fit-content;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            color: white;
            max-width: 80%;
        }
    }

    .choiceList {
        width: 100%;
        height: -moz-fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        .choice {
            padding: 10px;
            /* border-radius: 1vh; */
            font-size: 40px;
            text-align: center;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto;
            width: 80%;
            font-family: "Helenita";
            color: white;
            border-radius: 10px;
            /*user-select: initial !important;*/
            cursor: pointer;

            &:nth-child(1) {
                background-color: black;
            }

            &:nth-child(2) {
                background-color: #EB087C;
            }

            &.selected {
                color: #0FBFD1;
                transform: scale(1.05);
            }
        }
    }

    .toolsContainer {
        margin: auto;
        margin-top: 20px;
        margin-bottom: 3%;
        border-radius: 10px;
        background-color: white;
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 3.5px;
        padding-bottom: 3.5px;
        z-index: 1;
        box-shadow: inset 4px 4px 10px 0px rgba(0,0,0,0.25);
        height: fit-content;
        width: 350px;
        max-width: 90%;

        .toolsInner {
            width: 100%;
            height: fit-content;
            margin: auto;
            overflow-x: auto;
            overflow-y: hidden;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;

            &::-webkit-scrollbar {
                height: 10px;
                transform: scale(2);
            }

            &::-webkit-scrollbar-track {
                background-color: white;
                border-radius: 20px;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                box-shadow: none;
                background-color: #E90F79;
                border-radius: 20px;
            }

            .chosenColour {
                width: 35px;
                height: 35px;
                min-width: 35px;
                min-height: 35px;
                display: flex;
                border-radius: 5px;
                margin: auto;
                /*margin-right: 10px;*/
                /*user-select: initial !important;*/
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
                justify-content: end;
                align-items: end;
                margin-bottom: 3.5px;
                margin-top: 3.5px;

                .tab {
                    width: 0;
                    height: 0;
                    margin-top: auto;
                    margin-left: auto;
                    border-bottom-right-radius: inherit;
                    border-bottom: 10px solid #E90F79;
                    border-left: 10px solid transparent;
                }
            }

            .chosenPen {
                width: 35px !important;
                height: 35px !important;
                min-width: 35px;
                min-height: 35px;
                margin: auto;
                display: flex;
                border-radius: 50%;
                /*margin-left: 1vh;*/
                border: 2px solid #E90F79;
                /*margin-right: 1vh;*/
                position: relative;
                /*user-select: initial !important;*/
                cursor: pointer;
                justify-content: center;
                align-items: center;
                margin-left: 2px;
                margin-right: 2px;
                margin-bottom: 3.5px;
                margin-top: 3.5px;

                .innerPen {
                    background-color: black;
                    border-radius: 50%;
                    /*margin: auto;*/
                }
            }

            .tool {
                width: 35px;
                height: 35px;
                margin: auto;
                /*                margin-left: 0.25vh;
                margin-right: 0.25vh;*/
                /*user-select: initial !important;*/
                cursor: pointer;
                margin-left: 2px;
                margin-right: 2px;
                margin-bottom: 3.5px;
                margin-top: 3.5px;

                &.toggled {
                    border-radius: 5px;
                    background-color: #E90F79;
                }

                &.flipped {
                    transform: scaleX(-1);
                }
            }
        }




        .colourSection {
            width: 35.1vh !important;
            height: fit-content;
            padding: 0.5vh;
            margin: auto !important;
            position: absolute;
            left: 0;
            right: 0;
            top: 20vh;
            bottom: 0;
            border: solid 0.45vh grey;
            border-radius: 1vh;
            display: none !important;
            background: white;

            &.show {
                display: flex !important;
            }
        }



        .penSizeSection {
            width: fit-content;
            height: fit-content;
            padding: 1vh;
            margin: auto;
            flex-direction: row;
            position: absolute;
            left: 0;
            right: 0;
            top: 20vh;
            bottom: 0;
            border: solid 0.45vh grey;
            border-radius: 1vh;
            background: white;
            display: none;

            &.show {
                display: flex;
            }

            .penContainer {
                display: flex;
                margin: auto;
                height: 4vh;
                width: 4vh;
                /*user-select: initial !important;*/
                cursor: pointer;

                &.show {
                    border: 0.25vh solid #E90F79;
                    border-radius: 50%;
                }

                .penSize {
                    margin: auto;
                    border-radius: 50%;
                    display: flex;
                    background-color: black;

                    .innerCircle {
                        width: 50%;
                        height: 50%;
                        margin: auto;
                        background-color: white;
                        border-radius: 50%;
                        display: none;

                        &.show {
                            display: initial;
                        }
                    }
                }
            }
        }
    }

    .writeImg {
        width: 85%;
        max-width: 85%;
        /*margin: auto;*/
        margin-left: auto;
        margin-right: auto;
        border-radius: 10px;
        background-color: white;
        box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.3);
    }

    .writeBox {
        width: 80%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 3%;
        height: fit-content;
        text-align: center;
        background-color: white;
        border-radius: 10px;
        font-family: "Interstate";
        font-size: 25px;
        border: none;
        padding: 5px;
        padding-top: 20px;
        padding-bottom: 20px;
        box-shadow: inset 4px 4px 10px 0px rgba(0,0,0,0.25);
    }

    .submit {
        width: 100%;
        height: fit-content;
        /*background-color: #0FBFD1;*/
        display: flex;
        /*box-shadow: 0px -4px 11px 2px rgba(0,0,0,0.3);*/
        transition: 0.25s all ease-in-out;
        margin: auto;
        margin-bottom: 3%;

        .subButton {
            width: 80%;
            font-size: 35px;
            font-family: "Gagalin";
            text-align: center;
            background-color: #E90F79;
            border-radius: 10px;
            color: white;
            margin: auto;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
            box-shadow: 0px 6px 0px 0px #A3195B;
            margin-bottom: 3%;
            /*user-select: initial !important;*/

            cursor: pointer;

            .loading {
                display: inline-block;
                width: 35px;
                height: 35px;
                border: 5px solid rgba(255,255,255,.3);
                border-radius: 50%;
                border-top-color: #fff;
                animation: spin 1s ease-in-out infinite;
                -webkit-animation: spin 1s ease-in-out infinite;
            }

            @keyframes spin {
                to {
                    -webkit-transform: rotate(360deg);
                }
            }

            @-webkit-keyframes spin {
                to {
                    -webkit-transform: rotate(360deg);
                }
            }
        }

        &.disabled {
            opacity: 0;
        }
    }

    .revealSection {
        width: 90%;
        height: 85%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: auto;
        margin-top: unset;

        .revealTitle {
            width: 100%;
            font-size: 3vh;
            text-align: center;
            margin: auto;
        }

        .storyList {
            width: 100%;
            height: 90%;
            margin: auto;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            position: relative;
            overflow-y: auto;
            margin-bottom: 3%;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: white;
                border-radius: 20px;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                box-shadow: none;
                background-color: #E90F79;
                border-radius: 20px;
            }

            .storyItem {
                width: 300px;
                height: 300px;
                margin: auto;
                left: 0;
                right: 0;
                font-size: 35px;
                font-family: "Interstate";
                text-align: center;
                border-radius: 20px;
                background-color: white;
                box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.3);
                position: absolute;
                display: flex;

                &.empty {
                    background-color: red !important;
                }


                &:nth-child(1) {
                    top: 0px;
                    z-index: 10;
                }

                &:nth-child(2) {
                    top: 20px;
                    z-index: 9;
                }

                &:nth-child(3) {
                    top: 40px;
                    z-index: 8;
                }

                &:nth-child(4) {
                    top: 60px;
                    z-index: 7;
                }

                &:nth-child(5) {
                    top: 80px;
                    z-index: 6;
                }

                &:nth-child(6) {
                    top: 100px;
                    z-index: 5;
                }

                &:nth-child(7) {
                    top: 120px;
                    z-index: 4;
                }

                &:nth-child(8) {
                    top: 140px;
                    z-index: 3;
                }

                &.hidden {
                    opacity: 0;
                }



                .itemContent {
                    width: 100%;
                    margin: auto;
                    font-size: 30px;
                    text-align: center;
                    border-radius: inherit;

                    &.text {
                        height: fit-content;
                        width: 90%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        overflow-wrap: break-word;
                    }

                    &.empty {
                        font-family: "Gagalin";
                        font-size: 150px;
                        color: white;
                        text-align: center;
                        height: fit-content;
                        width: 90%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }

                .itemInfo {
                    position: absolute;
                    left: -0.25vh;
                    top: -0.25vh;
                    display: flex;
                    flex-direction: row;

                    .number {
                        padding: 1vh;
                        border-right: solid 0.25vh black;
                        border-bottom: solid 0.25vh black;
                        border-top: solid 0.25vh black;
                        border-left: solid 0.25vh black;
                        font-size: 1.5vh;
                        background-color: white;
                    }

                    .name {
                        padding: 1vh;
                        border-right: solid 0.25vh black;
                        border-bottom: solid 0.25vh black;
                        border-top: solid 0.25vh black;
                        border-bottom-right-radius: 1vh;
                        font-size: 1.5vh;
                        background-color: white;
                    }
                }
            }
        }

        .voteList {
            width: fit-content;
            max-width: 90%;
            max-height: 100%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: auto;
            justify-content: center;
            margin-top: 3%;
            overflow-y: auto;

            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-track {
                background-color: white;
                border-radius: 20px;
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }

            &::-webkit-scrollbar-thumb {
                box-shadow: none;
                background-color: #E90F79;
                border-radius: 20px;
            }

            .voteItem {
                max-width: 200px;
                max-height: 200px;
                width: 40%;
                aspect-ratio: 1/1;
                left: 0;
                right: 0;
                text-align: center;
                border-radius: 10px;
                background-color: white;
                box-shadow: 4px 4px 10px 1px rgba(0,0,0,0.3);
                position: relative;
                display: flex;
                margin: 10px;
                /*user-select: initial !important;*/
                cursor: pointer;

                &.fade {
                    opacity: 0.5;
                    /*user-select: none !important;*/
                    cursor: none !important;
                }

                &.empty {
                    background-color: red !important;
                    /*box-shadow: unset !important;*/
                }

                &.chosen {
                    transform: scale(1.1);
                    border: solid 5px #EC0080;
                }

                .itemContent {
                    width: 100%;
                    margin: auto;
                    font-size: 10px;
                    text-align: center;
                    border-radius: inherit;

                    &.text {
                        height: fit-content;
                        font-family: "Helenita";
                        width: 90%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        overflow-wrap: break-word;
                    }

                    &.empty {
                        font-family: "Gagalin";
                        font-size: 100px;
                        color: white;
                        height: fit-content;
                        width: 90%;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }
                }
            }
        }

        .button {
            width: 50%;
            height: 5%;
            border: solid 0.25vh black;
            border-radius: 1vh;
            margin: auto;
            font-size: 2vh;
            text-align: center;
            /*user-select: initial !important;*/
            cursor: pointer;
        }
    }

    .voteSection {
        width: 100%;
        height: 75vh;
        display: flex;
        flex-direction: column;

        .voteButton {
            width: 75%;
            height: 40%;
            font-size: 7vh;
            text-align: center;
            margin: auto;
        }
    }
}

.skipContainer {
    background-color: #EC0080;
    border-radius: 1vh;
    width: fit-content;
    height: fit-content;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;

    .skipButton {
        font-family: "Gagalin";
        font-size: 4vh;
        color: white;
        text-align: center;
        padding: 1vh;
        margin: auto;
        position: relative;
    }
}